import BaseCrudService from "./baseCrud.service";

export default class ConfigManagerService extends BaseCrudService {

    constructor() {
        super('ConfigManager');
    }


    findConfigManagerByPaymentMethodProviderId(callback, paymentMethodProviverId) {
        return this.getRequest('FindConfigManagerByPaymentMethodProviderId', {paymentMethodProviverId : paymentMethodProviverId}).then(callback);
    }
    
    savePaymentMethodProvider(callback, paymentMethodProviders) {
		this.postRequest('SavePaymentMethodProvider', {paymentMethodProviders: paymentMethodProviders}).then(callback);
	}

    findTickeConfigById(callback, ticketConfigId) {
        return this.getRequest('FindTicketConfigById', {id: ticketConfigId}).then(callback);
    }

    findAllTickeConfig(callback) {
        return this.getRequest('FindAllTickeConfig').then(callback);
    }

    saveTicketConfig(callback, ticketConfig) {
		this.postRequest('SaveTicketConfig', ticketConfig).then(callback);
    }
    
    listAllPaymentMethodProvider(){
        return this.getRequest('ListAllPaymentMethodProvider');
    }

}